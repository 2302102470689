import { useEffect, useState, useRef } from "react";
import ChatService from "../front-services/ChatService";
import Colors from "../utils/Colors";
import ReactMarkdown from "react-markdown";
import WideImageViewer from "../components/WideImageViewer";

export interface ChatWidgetStyle {
  color: string;
  name: string;
  fileUrl: string;
  welcomeMessage?: string;
}

const chatService = new ChatService();

const Chat = ({ startOpen = false, isMobile }: any) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [isClosing, setIsClosing] = useState(false);
  const [messages, setMessages]: any = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [assistant, setAssistant]: any = useState({ name: null, id: null });
  const [isEditor, setIsEditor] = useState(false);
  const chatMessagesRef: any = useRef(null);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [threadId, setThreadId]: any = useState(null);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState<any>(null);

  const [style, setStyle] = useState<ChatWidgetStyle>({
    color: "#a72eff",
    name: "Nome Assistente",
    fileUrl: "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
  });

  useEffect(() => {
    const updateDimensions = () => {
      const width = isMobile && isOpen ? "100%" : isOpen ? 500 : 100;
      const height = isMobile && isOpen ? "100%" : isOpen ? 700 : 100;

      // Send the dimensions to the parent window
      window.parent.postMessage({ width, height }, "*");
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    const updateDimensions = () => {
      const width = isMobile && isOpen ? "100%" : isOpen ? 500 : 100;
      const height = isMobile && isOpen ? "100%" : isOpen ? 700 : 100;
      // Send the dimensions to the parent window
      window.parent.postMessage({ width, height }, "*");
    };
    updateDimensions();

    window.addEventListener("onload", updateDimensions);

    return () => {
      window.removeEventListener("onload", updateDimensions);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    function handleMessage(event: any) {
      // Security check: Ensure the message is from the expected origin
      /* if (event.origin !== "https://your-parent-app-domain.com") {
        return;
      } */

      const data = event.data as ChatWidgetStyle;

      if (data.color) setStyle(data);
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assistant_id = urlParams.get("a_id");
    const assistant_name = urlParams.get("a_name");

    const isEditor = urlParams.get("editor");

    if (isEditor) {
      setIsEditor(true);
      setIsOpen(true);

      return;
    }

    if (assistant_id) {
      setAssistant({ name: assistant_name, id: assistant_id });

      chatService.getConfiguration(assistant_id).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const resStyle = data.configuration.style;
            setStyle({
              color: resStyle.mainColor,
              name: resStyle.name,
              fileUrl: resStyle.fileUrl,
              welcomeMessage: resStyle.welcomeMessage,
            });
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat messages when a new message is added
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages]);

  const toggleChatWidget = () => {
    if (isOpen) {
      // Start closing animation
      setIsClosing(true);
      // After 400ms (duration of animation), actually close the widget
      setTimeout(() => {
        setIsClosing(false);
        setIsOpen(false); // Fully close the widget
      }, 400); // This duration should match the CSS transition duration
    } else {
      setIsOpen(true); // Immediately open the widget
    }
  };

  const handleInputChange = (event: any) => {
    setInputMessage(event.target.value);
  };

  const getTime = () => {
    const date = new Date();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleUserMessage = async () => {
    if (inputMessage.trim() === "") return;

    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: inputMessage, time: getTime() },
    ]);

    setInputMessage("");

    let botResponse = await sendMessage(inputMessage);

    if (botResponse) {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          type: "bot",
          content: botResponse.messageReply,
          metadata: botResponse.metadata,
          time: getTime(),
        },
      ]);
    }
  };

  const sendMessage = async (userMessage: any) => {
    setIsMessageLoading(true);

    const response = await chatService.sendMessage(
      userMessage,
      assistant.id,
      threadId
    );

    if (response.ok) {
      const data = await response.json();
      setIsMessageLoading(false);
      setThreadId(data.threadId);
      return data;
    }
    setIsMessageLoading(false);

    return "Sorry, something went wrong. Please try again later.";
  };

  const CustomLinkRenderer = ({ href, children }: any) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  return (
    <>
      <div
        className="widget-chat-button p-1 w-14 h-14 rounded-full shadow-lg flex items-center justify-center text-white text-3xl focus:outline-none hover:bg-blue-600 transition-colors duration-200 ease-in-out"
        style={{
          backgroundColor: style.color,
          boxShadow: `0 4px 6px -1px ${style.color}, 0 2px 4px -1px ${style.color}`,
          opacity: !isOpen || !isClosing ? "1" : "0",
          transition: "opacity 0.15s ease-in-out", // Use 'ease-in-out' for smoother transition
        }}
        onClick={!isEditor ? toggleChatWidget : undefined}
      >
        <i className="fas fa-comments" style={{ fontSize: "25px" }}></i>
      </div>

      <div
        style={{
          position: "fixed",
          right: isMobile ? "0px" : "20px",
          width: isMobile ? "100%" : "400px",
          bottom: isMobile ? "0px" : "20px",
          height: isMobile ? "100%" : "600px",
          maxHeight: isClosing
            ? "0px" // When it's closing, the max-height should go to 0
            : isOpen // When it's open, use the appropriate max-height for open state
            ? isMobile
              ? "100%" // Mobile open state
              : "600px" // Desktop open state
            : "0",
          boxShadow: "1px 4px 4px 1px #f0f0f0, 0 2px 4px -1px #f0f0f0",
          padding: "0px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          border: isOpen || isClosing ? "1px solid #f1f1f1" : "none",
          borderRadius: isMobile ? "0px" : "12px",
          overflow: "hidden",
          opacity: isOpen || isClosing ? "1" : "0",
          transition:
            "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, border 0.4s ease-in-out", // Use 'ease-in-out' for smoother transition
        }}
      >
        {isFullScreen ? (
          <WideImageViewer
            image={fullScreenImage}
            maxHeight={isMobile ? undefined : "600px"}
            maxWidth={isMobile ? undefined : "400px"}
            setFullScreen={setIsFullScreen}
            isFullScreen={isFullScreen}
            setFullScreenImage={setFullScreenImage}
          />
        ) : (
          <>
            {" "}
            <div
              className="widget-header"
              style={{ backgroundColor: style.color, borderColor: style.color }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={style.fileUrl}
                  style={{ borderRadius: "50%" }}
                  alt="Assistant Icon"
                  className="header-icon inline-block"
                />
                <div className="header-title-description">
                  <span className="text-lg font-semibold ml-2 widget-header-title">
                    <b>{style.name}</b>
                  </span>
                  <span className="header-description">
                    Replies in real-time
                  </span>
                </div>
              </div>

              <button
                className="minimize-button"
                style={{
                  backgroundColor: Colors.lightenHexColor(style.color, 35),
                }}
                onClick={toggleChatWidget}
                disabled={isEditor}
              >
                <i className="fa-solid fa-x"></i>
              </button>
            </div>
            <div className="widget-chat-messages" ref={chatMessagesRef}>
              {style.welcomeMessage && (
                <div
                  style={{
                    marginBottom: "10px",
                    backgroundColor: Colors.lightenHexColor(style.color, 90),
                  }}
                  className="widget-chatbot-message"
                >
                  <ReactMarkdown>{style.welcomeMessage}</ReactMarkdown>
                </div>
              )}
              {messages.map((message: any, index: any) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    backgroundColor:
                      message.type !== "user"
                        ? Colors.lightenHexColor(style.color, 90)
                        : "",
                  }}
                  className={
                    message.type === "user"
                      ? "widget-user-message"
                      : "widget-chatbot-message"
                  }
                >
                  <ReactMarkdown
                    components={{
                      a: CustomLinkRenderer, // Override how links are rendered
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                  {message.metadata &&
                    message.metadata.map((meta: any, index: any) => {
                      if (
                        meta.type === "image" &&
                        meta.value.startsWith("https://i.ibb.co/")
                      ) {
                        return (
                          <div className="message-attachment">
                            <WideImageViewer
                              image={meta.value}
                              maxHeight={isMobile ? "100%" : "600px"}
                              maxWidth={isMobile ? "100%" : "400px"}
                              setFullScreen={setIsFullScreen}
                              isFullScreen={isFullScreen}
                              setFullScreenImage={setFullScreenImage}
                            />
                          </div>
                        );
                      } else if (meta.type === "image") {
                        return (
                          <img
                            key={index}
                            src={meta.value}
                            alt="immagine"
                            className="message-attachment"
                          />
                        );
                      }

                      return <></>;
                    })}
                  <p className="message-time">{message.time}</p>
                </div>
              ))}
              {isMessageLoading && (
                <div
                  className="widget-chatbot-message"
                  style={{
                    backgroundColor: Colors.lightenHexColor(style.color, 90),
                  }}
                >
                  <span
                    className="loader-container"
                    style={{
                      backgroundColor: Colors.lightenHexColor(style.color, 90),
                    }}
                  >
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </span>
                </div>
              )}
            </div>
            <div
              className="widget-chat-footer"
              style={{
                backgroundColor: style.color,
              }}
            >
              <div className="widget-chat-input-container border border-grey-400">
                <textarea
                  className="widget-chat-input"
                  disabled={isEditor}
                  style={{ fontFamily: "roboto, sans-serif", fontSize: "15px" }}
                  placeholder="Type a message..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleUserMessage();
                    }
                  }}
                />
                <button
                  disabled={isEditor || isMessageLoading}
                  className="widget-send-button flex-none m-0 p-0 text-white"
                  style={{ backgroundColor: style.color }}
                  onClick={handleUserMessage}
                >
                  <i
                    className="fa-regular fa-paper-plane"
                    style={{ color: "whitesmoke", fontSize: "16px" }}
                  ></i>
                </button>
              </div>
              <div className="powered-by">
                <span
                  style={{
                    fontSize: "10px",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  Powered By
                </span>
                <a
                  href="https://plutoniosolutions.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "12px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  PlutoniosSolutions
                  <img
                    src="https://plutoniosolutions.com/assets/img/SmallCircle.webp"
                    alt="Plutonios Solutions Logo"
                    className="inline-block mr-1"
                    style={{
                      width: "10px",
                      marginLeft: "2px",
                      verticalAlign: "baseline",
                      border: "1px solid white",
                      borderRadius: "50%",
                    }}
                  />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Chat;
